.StripeElement {
  height: 3.5rem;
  padding: 1.2rem 0.5rem 1rem 0.5rem;
  width: 100%;
  color: #000;
  font-size: 18px;
  background-color: #f3f6f9;
  border-bottom: 2px solid #b5c9db;
  border-radius: 0px;
  transition: 0.2s ease-in-out;
  box-shadow: 0px;
}

@media screen and (max-width: 1000px) {
  .StripeElement {
    font-size: 15px !important;
  }
}

.StripeElement:hover,
.StripeElement:focus {
  border-bottom: 2px solid #3488f8;
}

.StripeElement--focus {
  border-bottom: 2px solid #3488f8;
}

.StripeElement.StripeElement--complete {
  border-bottom: 2px solid #4cd99e;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #f3f6f9 !important;
}
